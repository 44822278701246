import React, { Component } from 'react';

import Link from "./Link";

import processText from '../../views/processText';

import "./NavBar.css";

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            scrolled: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll() {
        if (window.scrollY > 20) {
            if (!this.state.scrolled) {
                this.setState({ scrolled: true });
            }
        } else {
            this.setState({ scrolled: false });
        }
    }
    render() {
        return (
            <div className="fixed-top">
                {this.props.children}
                <nav className={"navbar navbar-expand-md navbar-light" + (this.props.white ? " nav-white" : "") + (this.state.scrolled ? " navbar-scrolled" : "") + (this.state.collapsed ? "" : " nav-show-bg")}>
                    <div className="container-fluid px-0">
                        <div className="navbar-header">
                            <Link onClick={() => {
                                window.scrollTo(0, 0);
                            }} className="navbar-brand lobster" to="/#">{this.props.brand || "Dockington"}</Link>
                        </div>
                        <div>
                            <button
                                type="button"
                                className={"navbar-toggler" + (this.state.collapsed ? " collapsed" : "")}
                                aria-controls="navbar-collpse"
                                aria-expanded="false"
                                onClick={() => { this.setState({ collapsed: !this.state.collapsed }); }}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        
                        <div className={"collapse navbar-collapse" + (this.state.collapsed ? "" : " show")} id="navbar-collpse">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item divider">
                                    <Link className="nav-link" to="/">
                                        <div className="nav-text">{processText(this.props.translate("nav.product"))}</div>
                                    </Link>
                                </li>
                                <li className="nav-item divider">
                                    <Link className="nav-link" to="/dockington-reports">
                                        <div className="nav-text">{processText(this.props.translate("nav.reports"))}</div>
                                    </Link>
                                </li>
                                <li className="nav-item divider">
                                    <Link className="nav-link" to="/testimonials">
                                        <div className="nav-text">{this.props.translate("nav.testimonials")}</div>
                                    </Link>
                                </li>
                                <li className="nav-item divider">
                                    <Link className="nav-link" to="/blog">
                                        <div className="nav-text">{this.props.translate("nav.blog")}</div>
                                    </Link>
                                </li>
                                <li className="nav-item divider">
                                    <Link className="nav-link" to="/about">
                                        <div className="nav-text">{this.props.translate("nav.about")}</div>
                                    </Link>
                                </li>
                                {/* <li className="nav-item divider">
                                    <Link onClick={() => {
                                        if (window.gtag) {
                                            // Send signup event
                                            window.gtag('event', 'signup-nav', {
                                                'event_category' : 'signup-btn',
                                                'event_label' : 'Sign up from NavBar'
                                            });
                                        }
                                    }} className="nav-link" to="/signup">
                                        <div className="nav-text">{this.props.translate("nav.signup")} <b>&rarr;</b></div>
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default NavBar;
