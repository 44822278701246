import React from "react"

import LegalLayout from "../../views/legal/LegalLayout";
import CookiesPage from "../../views/legal/CookiesPage";

const Cookies = ({ location, pageContext: { locale } }) => (
    <LegalLayout location={location} locale={locale}>
        <CookiesPage />
    </LegalLayout>
);

export default Cookies;
