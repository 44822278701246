import React, { Fragment } from "react";

import { withTranslate } from "../../components/translate";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import List from '../../components/common/List';
import AlphaList from '../../components/common/AlphaList';
import Link from "../../components/common/Link";
import SEO from "../../components/SEO";

const CookiesPage = withTranslate(function ({ translate }) {
    return (
        <Fragment>
            <SEO
                title={translate("legal.cookies.head.title")}
                description={translate("legal.cookies.head.description")}
                path="/legal/cookies"
            />
            <ScrollToTopOnMount />
            <header className="container py-3 mt-4 mb-5">
                <div className="intro mt-3">
                    <h1>
                        {translate("legal.cookies.title")}
                    </h1>
                    <p className="h2-sub mb-0">
                        {translate("legal.cookies.subtitle")}
                    </p>
                </div>
            </header>
            <div className="container mb-5 pb-5">
                <p>
                    I cookie sono piccoli file di testo creati dai siti web visitati e memorizzati nella memoria del browser, che semplificano l'esperienza online salvando i dati di navigazione. I cookie ci aiutano a capire come usi i nostri siti, e consentono di mantenerti connesso ai siti memorizzando le tue preferenze. Questa pagina spiega come utilizziamo i cookie e altre tecnologie simili per aiutarci a garantire che i nostri Servizi funzionino correttamente, nonché analizzare e migliorare i Servizi in conformità con la nostra <Link to="/legal/privacy">Privacy Policy</Link>. Qualsiasi termine in maiuscolo utilizzato e non altrimenti definito di seguito ha il significato assegnato ad esso nella Privacy Policy.
                </p>
                <h2 id="how-we-use">
                    1. Come usiamo i cookie
                </h2>
                <p>I cookie svolgono un ruolo importante nell'aiutarci a fornire servizi personali, efficaci e sicuri. Sul nostro sito web utilizziamo i cookie per i seguenti scopi:</p>
                <AlphaList
                    items={[
                        <p><b>Per fornire i nostri Servizi.</b> Alcuni cookie sono essenziali per il funzionamento del nostro sito e dei nostri Servizi. Utilizziamo questi cookie per ricordare il Tuo stato di accesso in modo che Tu non debba effettuare il login mentre navighi attraverso il nostro sito. Ad esempio, quando accedi al Tuo account Dockington, utilizziamo un cookie in modo che Tu non debba effettuare nuovamente il login mentre navighi attraverso il sito web.</p>,
                        <p><b>Per analizzare e migliorare i nostri Servizi.</b> I cookie ci aiutano a capire come rendere il nostro sito e i nostri Servizi migliori per Te. I cookie ci dicono in che modo le persone raggiungono il nostro sito e ci forniscono informazioni su miglioramenti da apportare al nostro sito e ai nostri Servizi.</p>,
                    ]}
                />

                <h2 id="other-technologies">2. Come usiamo altre tecnologie</h2>
                <p>Utilizziamo Google Analytics, che utilizza cookie e tecnologie simili, per raccogliere e analizzare informazioni statistiche sull'utilizzo dei Servizi e per verificarne il corretto funzionamento. Al fine di rispettare la privacy dei nostri utenti, il servizio è utilizzato con la modalità &ldquo;<code>_anonymizeip</code>&rdquo; che consente di <a href="https://support.google.com/analytics/answer/2763052">mascherare gli indirizzi IP</a> degli utenti che navigano sul nostro sito. Puoi visionare la cookie policy di Google Analytics sul <a href="https://support.google.com/analytics/answer/6004245">sito di Google</a>. </p>
                
                <h2 id="how-to-manage-cookies">3. Come gestire i cookie</h2>
                <p>Puoi scegliere di abilitare o disabilitare i cookie, modificando le impostazioni del Tuo browser  che trovi seguendo i link di seguito indicati. Tieni presente che se scegli di disabilitare i cookie, alcune funzionalità del nostro sito o dei nostri Servizi potrebbero non funzionare come previsto.</p>
                <List
                    simple
                    items={[
                        <a href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a>,
                        <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie#w_impostazioni-dei-cookie">Firefox</a>,
                        <a href="https://support.apple.com/it-it/HT201265">Safari</a>,
                        <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>,
                        <a href="https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">Microsoft Edge</a>,
                        <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a>,
                    ]}
                />
                <p>Se desideri impedire a Google Analytics di raccogliere e analizzare le tue informazioni sull'utilizzo del nostro sito e dei nostri Servizi, puoi installare questo plug-in per il Tuo browser.</p>
            </div>
        </Fragment>
    )
});

export default CookiesPage;
