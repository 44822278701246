import React from "react";

import List from "./List";

const AlphaList = function (props) {
    return (
        <List
            {...props}
            items={props.items.map((item, i) => (
                { marker: String.fromCharCode("a".charCodeAt(0) + i) + ".", text: item }
            ))}
        />
    );
}

export default AlphaList;
