import React, { Fragment } from "react";

import Layout from "../../components/Layout";
import NavBar from "../../components/common/NavBar";

import withTranslate from "../../components/translate";

import "./LegalLayout.css";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import Link from "../../components/common/Link";

const parsePath = function (pathname) {
    if (pathname.endsWith("/")) {
        pathname = pathname.substr(0, pathname.length - 1);
    }
    if (pathname.startsWith("/en/")) {
        pathname = pathname.substr(3);
    }
    return pathname;
}

const LegalLayoutView = withTranslate(function ({ location, translate, children }) {
    let currentPath = parsePath(location.pathname);
    return (
        <Fragment>
            <NavBar translate={translate} />
            <Row className="mx-0">
                <Col xs12 l className="sidebar">
                    <ul className="sidebar-list">
                        <Link activeClassName="active" partiallyActive className="top" to="/legal/privacy">{translate("legal.privacy.title")}</Link>
                        <div className="minor-list">
                        {currentPath === "/legal/privacy" && translate("legal.privacy.sections").map((section) => (
                            <Link className="minor" to={"/legal/privacy#" + section.id}>{section.name}</Link>
                        ))}
                        </div>
                        <Link activeClassName="active" partiallyActive className="top" to="/legal/cookies">{translate("legal.cookies.title")}</Link>
                        <div className="minor-list">
                        {currentPath === "/legal/cookies" && translate("legal.cookies.sections").map((section) => (
                            <Link className="minor" to={"/legal/cookies#" + section.id}>{section.name}</Link>
                        ))}
                        </div>
                        <Link activeClassName="active" partiallyActive className="top" to="/legal/terms">{translate("legal.terms.title")}</Link>
                        <div className="minor-list">
                        {currentPath === "/legal/terms" && translate("legal.terms.sections").map((section) => (
                            <Link className="minor" to={"/legal/terms#" + section.id}>{section.name}</Link>
                        ))}
                        </div>
                    </ul>
                </Col>
                <Col xs12 l className="legal">
                    {children}
                </Col>
            </Row>
        </Fragment>
    )
});

const LegalLayout = function ({ location, locale, children }) {
    return (
        <Layout location={location} locale={locale}>
            <LegalLayoutView location={location}>
                {children}
            </LegalLayoutView>
        </Layout>
    );
};

export default LegalLayout;
