import React from 'react';

import Link from "../components/common/Link";
import Dockington from "../components/common/Dockington";
import List from '../components/common/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const processText = function (text, id) {
    if (Array.isArray(text)) {
        return text.map((piece, i) => processText(piece, `${id || ""}a${i}`));
    } else if (text && typeof text === "object") {
        if (text.link) {
            return (
                <Link to={text.link}>{processText(text.text)}</Link>
            );
        } else if (text.outsideLink) {
            return (
                <a href={text.outsideLink} target="_blank" rel="noopener noreferrer">{processText(text.text)}</a>
            );
        } else if (text.b) {
            return (
                <b key={id || "b"}>{processText(text.b)}</b>
            );
        } else if (text.br) {
            return (
                <br key={id || "br"} />
            );
        } else if (text.dockington) {
            return (
                <Dockington key={id || "dockington"} />
            );
        }else if (text.icon) {
            return (
                <FontAwesomeIcon
                    key={text.icon.icon ? text.icon.icon : text.icon}
                    {...(text.icon.icon ? text.icon : { icon: text.icon })}
                />
            );
        } else if (text.underline) {
            return <u key={id || "underline"}>{processText(text.underline, `u:${id || ""}`)}</u>
        } else if (text.italic) {
            return <i key={id || "italic"}>{processText(text.italic, `u:${id || ""}`)}</i>
        } else if (text.strike) {
            return <del key={id || "strike"} className="text-muted">{processText(text.strike, `d:${id || ""}`)}</del>
        } else if (text.block) {
            return <div key={id || "block"} style={{ display: "inline-block" }}>{processText(text.block, `b:${id || ""}`)}</div>
        } else if (text.quote) {
            return <span key={id || "quote"}>&ldquo;{processText(text.quote, `s:${id || ""}`)}&rdquo;</span>
        } else if (text.dreports) {
            return <div key={id || "dreports"} style={{ display: "inline-block" }}><span className="lobster">Dockington</span> Reports</div>
        } else if (text.insider) {
            return <div key={id || "dinsider"} style={{ display: "inline-block" }}><span className="lobster">Dockington</span> Insider</div>
        } else if (text.lobster) {
            return <span key={id || "lobster"} className="lobster">{processText(text.lobster, id || 'lobster')}</span>
        } else if (text.signature) {
            return <span key={id || "signature"} className="signature">{processText(text.signature, id || 'signature')}</span>
        } else if (text.color) {
            return <span key={id || "color"} style={{ color: text.color }}>{processText(text.text)}</span>
        } else if (text.h2) {
            return <h2 key={id || "h2"}>{processText(text.h2)}</h2>
        } else if (text.list) {
            return (
                <List key={id || "list"}
                    className="mt-1"
                    simple={text.simple}
                    items={text.list.map((item, idx) => (
                        typeof item === "object" ?
                            Object.assign({ ...item, text: processText(item.text, `${id || ""}l:${idx}`) }, item.icon ? { marker: <FontAwesomeIcon color={item.iconColor} icon={item.icon} /> } : undefined)
                            : processText(item, `${id || ""}l:${idx}`)
                    ))}
                />
            );
        }
    }
    return text;
};

export default processText;
