import React from "react";

const List = function ({ items, simple, className }) {
    return (
        <div className={"list" + (simple ? " simple" : "") + (className ? (" " + className) : "")}>
        {items.map((item) => (
            <div className="list-item">
                {(item.marker || simple) && <div className="list-marker">{item.marker || "- "}</div>}
                <div>{item.text ? item.text : item}</div>
            </div>
        ))}
        </div>
    );
}

export default List;
